import Translation from '../components/data/Translation';

const statuses = {
    upcoming: Translation.get('statuses.upcoming', 'common'),
    online: Translation.get('statuses.online', 'common'),
    request: Translation.get('statuses.request', 'common'),
    draft: Translation.get('statuses.draft', 'common'),
    live: Translation.get('statuses.live', 'common'),
    create: Translation.get('statuses.create', 'common'),
    offline: Translation.get('statuses.offline', 'common'),
    removed: Translation.get('statuses.removed', 'common'),
    inreview: Translation.get('statuses.inreview', 'common'),
    declined: Translation.get('statuses.declined', 'common'),
    scheduled: Translation.get('statuses.scheduled', 'common'),
    published: Translation.get('statuses.published', 'common'),
    unpublished: Translation.get('statuses.unpublished', 'common'),
    pending: Translation.get('statuses.pending', 'common'),
    submitted: Translation.get('statuses.submitted', 'common'),
    inputReady: Translation.get('statuses.inputReady', 'common'),
    active: Translation.get('statuses.active', 'common'),
    inactive: Translation.get('statuses.inactive', 'common'),
    localisation: Translation.get('statuses.localisation', 'common')
};

export default statuses;
